import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "isDeletedFlag"]

  remove(event) {
    event.preventDefault();

    this.isDeletedFlagTarget.value = "true";
    this.element.classList.add("d-none");
  }
}
