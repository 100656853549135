import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dateInput", "vehicleInput", "output" ]
  static values = {
    url: String
  }

  connect() {
    this.fetchAvailability();
  }

  fetchAvailability(event) {
    // let url = new URL(this.outputTargets)
    if(this.dateInputTarget.value) {
      this.outputTargets.forEach((target) => {
        let url = new URL(this.urlValue);
        this.dateInputTargets.forEach((input) => {
          url.searchParams.append("dates[]", input.value);
        });
        url.searchParams.set("vehicle_id", target.dataset.vehicleId);
        fetch(url).then(resp => resp.text()).then(html => {
          target.innerHTML = html
        })
      })
    }
  }
}
