import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "output" ]
  static values = {
    url: String
  }

  connect() {
    this.renderPreview(null);
  }

  renderPreview(event) {
    const formElement = this.formTarget;
    const formData = new FormData(formElement);

    let url = new URL(this.urlValue);
    fetch(this.urlValue + "?" + new URLSearchParams(formData).toString(), {
      method: "GET"
    }).then(resp => resp.text())
      .then(
        resp => { this.outputTarget.innerHTML = resp}
      )
  }
}
