import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template", "outlet" ]

  add(event) {
    event.preventDefault();
    let element = document.createElement("div");
    element.innerHTML = this.templateTarget.innerHTML
    this.outletTarget.appendChild(element);
  }
}
