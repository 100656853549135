import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "lineItemExtra", "lineItemTravelCost", "lineItemDiscount", "lineItemPackage", "list"]

  addExtra(event) {
    event.preventDefault();
    event.target.blur();
    let extraDiv = document.createElement("div");
    extraDiv.innerHTML = this.lineItemExtraTarget.innerHTML;
    this.listTarget.appendChild(extraDiv);

    extraDiv.querySelector('input:not([type="hidden"]), select').focus();
  }

  addTravelCost(event) {
    event.preventDefault();
    event.target.blur();

    let extraDiv = document.createElement("div");
    extraDiv.innerHTML = this.lineItemTravelCostTarget.innerHTML;
    this.listTarget.appendChild(extraDiv);

    extraDiv.querySelector('input:not([type="hidden"]), select').focus();
  }

  addPackage(event) {
    event.preventDefault();
    event.target.blur();

    let extraDiv = document.createElement("div");
    extraDiv.innerHTML = this.lineItemPackageTarget.innerHTML;
    this.listTarget.appendChild(extraDiv);

    extraDiv.querySelector('input:not([type="hidden"]), select').focus();
  }

  addDiscount(event) {
    event.preventDefault();
    event.target.blur();

    let extraDiv = document.createElement("div");
    extraDiv.innerHTML = this.lineItemDiscountTarget.innerHTML;
    this.listTarget.appendChild(extraDiv);

    extraDiv.querySelector('input:not([type="hidden"]), select').focus();
  }
}
